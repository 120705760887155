/* eslint-disable jsdoc/check-param-names */
/* eslint-disable camelcase */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ALLOWED_USER_REGISTRATION_FIELDS, ALLOWED_PROFILE_FIELDS } from 'lib/constants';
import { AUTHENTICATED, UNAUTHENTICATED, UNINITIALIZED } from 'lib/myNewsConstants';
import { SCREENS, POST_LOGIN_SCREEN } from 'lib/loginFormConstants';
import { stub as $t } from '@nbcnews/analytics-framework';
import { setCookie, getCookie, deleteCookie } from 'lib/cookies';
import { bedrockApi as api, decodeJWT } from './utils';

export const INITIAL_STATE = {
  user: {
    user_id: null,
    email: '',
    email_verified: false,
    last_name: '',
    first_name: '',
    birth_year: '',
    gender: '',
    address_line1: '',
    address_line2: null,
    state: '',
    zipcode: '',
    phone_number: '',
  },
  social: {
    idToken: '',
    firstName: '',
    platform: '',
    isNewUser: false,
  },
  access_token: null,
  error: '',
  loading: false,
  brand: '',
  showAuthenticationModal: false,
  screen: SCREENS.START,
  signInType: '',
  authenticationState: UNINITIALIZED,
  showGate: true,
  pageView: null,
  isOverlay: false,
};

/**
 * Creates the zustand store with initial state and actions for the Bedrock Registration service
 * @param {Function} set - The function to update the state.
 * @param {Function} get - The function to get the current state.
 * @returns {object} The store with state and actions.
 */
export const bedrockRegistrationStore = (set, get) => ({
  ...INITIAL_STATE,
  /**
   * Tracks user events and sends them to the analytics framework.
   * @param {object} payload - The payload to track.
   * @param {string} payload.action - The action to track.
   * @param {string} payload.method - The method to track.
   * @param {string} payload.userId - The user ID to track, named userId instead of user_id so as to be overwriteable in obj destructuring.
   * @returns {void}
   */
  track: (payload) => {
    const userId = payload.userId || get().user.user_id;
    const capitalizedScreen = get().screen.charAt(0).toUpperCase() + get().screen.slice(1);
    // ternary because not everything will report a method and we don't want to send unecessarily empty fields
    const method = payload.method ? `${get().pageView}:${get().isOverlay ? 'ModalOverlay:' : ''}${capitalizedScreen}:${payload.method}` : null;
    // userId must be null when not available, analytics framework does not accept undefined
    const nullableUserId = userId || null;
    const surmizedAuthState = userId ? AUTHENTICATED : UNAUTHENTICATED;
    const finalPayload = {
      ...payload,
      userId: nullableUserId,
      authState: surmizedAuthState,
      method,
    };
    // track the event
    $t('track', 'ramen_authflow', finalPayload);
  },
  /**
   *
   */
  reset: () => {
    set({ ...INITIAL_STATE }, false, 'reset');
    // Clear the cookies
    deleteCookie('access_token');
    deleteCookie('user_name');
    deleteCookie('SESSION');
    deleteCookie('bedrock_session');
  },

  /**
   * Sets the loading state in the store.
   * @param {boolean} loading - The loading state.
   * @returns {void}
   */
  setLoading: (loading) => set({ loading }, false, 'setLoading'),

  /**
   * Sets the authentication state in the store.
   * @param {string} authenticationState - The authentication state.
   * @returns {void}
   */
  setAuthenticationState: (authenticationState) => {
    if (authenticationState !== UNINITIALIZED) {
      get().setLoading(false);
    }

    // reset cookie value for user_name if NOT authenticated
    if (authenticationState === UNAUTHENTICATED) {
      get().reset();
    }

    if (authenticationState === AUTHENTICATED) {
      if (!POST_LOGIN_SCREEN.includes(get().screen)) {
        set({ showGate: false }, false, 'setAuthenticationState');
      }
    }
    set({ authenticationState }, false, 'setAuthenticationState');
  },

  /**
   * Checks if the token is expired.
   * @param {string} token - The JWT token.
   * @returns {boolean} The value of the `isExpired` flag.
   */
  isTokenExpired: (token = '') => {
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
      return {};
    }
    try {
      const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
      const decoded = JSON.parse(decodedJson);
      const { exp } = decoded;
      const tokenExpiry = exp * 1000;
      const isExpired = Date.now() >= tokenExpiry;
      return isExpired;
    } catch (error) {
      return true;
    }
  },

  /**
   * Sets the email in the store.
   * @param {string} email
   */
  setEmail: (email) => set({ user: { email } }, false, 'setEmail'),

  /**
   * Searches for a user by email and vertical/brand
   * @param {string} email
   * @param {VerticalType} brand
   * @returns {Promise<object>} The response from the API.
   */
  searchUserByEmail: async (email, brand) => {
    get().setLoading(true);
    set({ brand }, false, 'searchUserByEmail');
    try {
      const response = await api('/users/search/profile', {
        body: {
          email,
          brand,
        },
      });
      get().setLoading(false);
      return response;
    } catch (error) {
      return error;
    }
  },

  /**
   * Checks user password for login
   * @param {string} password
   * @returns {string} The status of the login.
   */
  userSignin: async ({ password, trackingPayload }) => {
    let status;

    get().setLoading(true);

    try {
      get().track(trackingPayload);
      const response = await api('/users/signin', {
        body: {
          email: get().user.email,
          password,
          brand: 'today',
          remember_me: false,
        },
      });

      if (response?.success) {
        // Only update user properties defined in initial state
        const userData = Object.fromEntries(
          Object.keys(INITIAL_STATE.user)
            .map((field) => [field, response.data[field]])
            .filter(([, value]) => !!value),
        );

        const user = { ...get().user, ...userData };
        get().track({
          action: 'SignIn:Success',
          method: `${trackingPayload.method}`,
          userId: user.user_id,
        });

        // user has an email registered with an account, send them to the login screen
        set({
          user,
          access_token: response?.data?.access_token,
          signInType: 'login',
        }, false, 'userSignin');

        if (response?.data?.access_token) {
          get().restoreSession(response?.data?.access_token);
        }

        status = await get().getLoginSuccessScreen(response?.data);
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSigninError');
      }
    } catch (err) {
      // something went wrong with the call to the api, set an error
      set({ error: 'There was an error logging in' }, false, 'userSigninCallError');
      get().setLoading(false);
      return status;
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Refreshes the user access token
   *  @returns {object} The user ID value
   */
  refreshUserToken: async () => {
    // check if user is already is logged in
    const access_token = getCookie('access_token');

    if (access_token) {
      const { sub: userId } = decodeJWT(access_token);

      if (userId) {
        // set user as authenticated when they have a valid token
        get().setAuthenticationState(AUTHENTICATED);
        // set user_id in store so it's available for MyNews API calls
        set({ user: { ...get().user, user_id: userId } }, false, 'refreshUserToken');

        // if access token is expired, refresh it
        if (get().isTokenExpired(access_token)) {
          try {
            await get().getUserToken(userId);
          } catch (err) {
            set({ error: err.message }, false, 'refreshUserTokenError');
          }
        }
      } else {
        // set as unauthenticated if no user id is found in token
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } else {
      // set as unauthenticated if no access token is found
      get().setAuthenticationState(UNAUTHENTICATED);
    }
  },

  /**
   * Retrieves a new user token from the API.
   * @param {string} userId - The ID of the user.
   * @returns {Promise<void>} Resolves when the token is successfully retrieved.
   */
  getUserToken: async (userId) => {
    try {
      const response = await api(`/users/${userId}/auth/token`, {
        method: 'POST',
        body: {
          bedrock_session: getCookie('bedrock_session'),
          scope: 'users:read_write',
        },
      });
      if (response?.success && response?.data?.access_token) {
        set({ access_token: response.data.access_token }, false, 'refreshUserToken');
        setCookie('access_token', response.data.access_token);
      } else {
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } catch (err) {
      set({ error: err.message }, false, 'refreshUserToken');
      get().setAuthenticationState(UNAUTHENTICATED);
      throw err;
    }
  },

  /**
   * Identifies a user with a one time codes
   * @param {string} oneTimeCode
   * @returns {string} The status of the one time code.
   */
  verifyOneTimeCode: async ({
    oneTimeCode: one_time_code,
    vertical: brand,
    trackingPayload,
  }) => {
    let status;

    try {
      const response = await api('/users/otp/verify', {
        body: {
          email: get().user.email,
          brand,
          one_time_code,
        },
      });
      // track the start of the one time code verification process
      get().track(trackingPayload);

      if (response?.success) {
        get().track({
          action: 'SignIn:Success',
          method: 'VerifyOTC:SubmitButtonClick',
          authState: 'authenticated',
          userId: response?.data?.user_id,
        });

        get().restoreSession(response?.data?.access_token);
        status = await get().getLoginSuccessScreen(response?.data);
      } else {
        // something went wrong with the api request, set an error
        set({ error: 'There was an error verifying the code' }, false, 'verifyOneTimeCode');
      }
    } catch (err) {
      set({ error: 'There was an error verifying the code' }, false, 'verifyOneTimeCode');
      return status;
    }
    return status;
  },

  /**
   * Sends the user a one time code for login verification
   * @param {object} props - The tracking props object.
   * @param {object} props.trackingPayload - The tracking payload object.
   * @param {string} props.trackingPayload.action - The action to track.
   * @param {string} props.trackingPayload.method - The method to track.
   * @returns {void}
   */
  requestOneTimeCode: async ({ trackingPayload }) => {
    try {
      // track the start of the one time code request process
      get().track(trackingPayload);
      const response = await api('/users/otp/request', {
        body: {
          email: get().user.email,
          brand: get().brand,
        },
        headers: {
          Authorization: `Bearer ${get().clientToken}`,
        },
      });
      if (!response?.success) {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'oneTimeCode');
      } else {
        // track the successful request of the one time code
        get().track({
          action: 'SignIn:Success',
          method: `${trackingPayload.method}`,
        });
      }
    } catch (err) {
      set({ error: 'There was an error, please try again' }, false, 'oneTimeCode');
    }
  },

  /**
   * Determines the login success screen based on user data.
   * @param {object} data - The user data.
   * @returns {string} The screen to display.
   */
  getLoginSuccessScreen: async (data) => {
    let screen;
    if (data?.email_verified) {
      // prompt user to enter their name if they haven't already
      screen = data?.first_name ? SCREENS.LOGIN_SUCCESS : SCREENS.USER_NAME;
    } else {
      // prompt user to verify email if they haven't already
      screen = SCREENS.VERIFY_OTC;
    }
    return screen;
  },

  /**
   * Clears the user token from the store and the cookie
   * @param {object} props - The signout props.
   * @param {object} props.trackingPayload - The tracking payload object.
   * @param {string} props.trackingPayload.action - The action to track.
   * @param {string} props.trackingPayload.method - The method to track.
   * @returns {string} The status of the signout.
   */
  userSignout: async () => {
    let status;
    get().setLoading(true);
    try {
      // track the start of the signout process
      get().track({
        action: 'SignOut:Start',
        method: 'SignOutButton:Click',
      });
      const response = await api(`/users/${get().user.user_id}/signout`, {
        body: {
          bedrock_session: getCookie('bedrock_session'),
        },
      });
      if (response?.success) {
        get().setAuthenticationState(UNAUTHENTICATED);
        // track successful signout
        const successPayload = {
          action: 'SignOut:Success',
          method: 'SignOutButton:Click',
        };
        get().track(successPayload);
        status = SCREENS.START;
        get().setLoading(false);
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSignout');
        get().setLoading(false);
      }
      return status;
    } catch (err) {
      set({ error: err.message }, false, 'userSignout');
    } finally {
      get().setLoading(false);
    }
    return status;
  },

  /**
   * Fetches the user profile based on the user id
   * @param {string} access_token - The access token to restore the session
   * @returns {void}
   */
  restoreSession: async (access_token) => {
    get().setLoading(true);

    // Early return if no token is provided
    if (!access_token) {
      get().setAuthenticationState(UNAUTHENTICATED);
      get().setLoading(false);
      return;
    }

    // Get the user id form the token and fetch profile
    const { sub: user_id } = decodeJWT(access_token);

    // Early return if no user id is found in token
    if (!user_id) {
      get().setAuthenticationState(UNAUTHENTICATED);
      get().setLoading(false);
      return;
    }

    // Fetch the user profile
    try {
      const response = await api(`/users/${user_id}/profile`, {
        body: {
          access_token,
          bedrock_session: getCookie('bedrock_session'),
        },
      });

      if (response.success) {
        const userData = response.data;
        set({ user: userData }, false, 'restoreSession');
        get().setAuthenticationState(AUTHENTICATED);
        // set the user_name cookie
        if (userData.first_name) {
          setCookie('user_name', userData.first_name);
        } else {
          // clear if no first name is available
          deleteCookie('user_name');
        }
      } else {
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } catch (err) {
      get().setAuthenticationState(UNAUTHENTICATED);
    }
    get().setLoading(false);
  },

  /**
   * Handles social login
   * @param {object} props - The social login props.
   * @param {object} props.socialUser - The social user object containing user details.
   * @param {string} props.socialUser.idToken - The social user ID token.
   * @param {string} props.socialUser.firstName - The social user first name.
   * @param {boolean} props.socialUser.isNewUser - Whether the user is new or not.
   * @param {string} props.vertical - The vertical/brand of the user.
   * @param {string} props.platform - The platform of the social login (e.g. 'google', 'apple').
   * @param {object} props.trackingPayload - The tracking payload object.
   * @param {string} props.trackingPayload.action - The action to track.
   * @param {string} props.trackingPayload.method - The method to track.
   * @returns {string} The status of the social login.
   */
  socialLogin: async ({
    socialUser, vertical, platform, trackingPayload,
  }) => {
    let status;
    get().setLoading(true);

    // data returned from the social login response
    const {
      idToken,
      firstName,
      isNewUser,
    } = socialUser;

    if (isNewUser) {
      // direct new users to the registration screen before logging in
      set({ social: { idToken, firstName, platform }, brand: vertical }, false, 'socialLogin');
      get().setLoading(false);
      return SCREENS.SOCIAL_REGISTRATION;
    }

    try {
      // track the start of the social login process
      get().track(trackingPayload);
      const response = await api('/users/social/signin', {
        body: {
          id_token: idToken,
          brand: vertical,
          platform,
        },
      });

      if (response?.success) {
        // track successful login
        const successPayload = {
          action: 'SignIn:Success',
          method: `${trackingPayload.method}`,
          userId: response?.data?.user_id,
        };
        get().track(successPayload);
        // user successfully logs in with social login
        set({ user: response?.data?.access_token }, false, 'loginSuccess');
        get().restoreSession(response?.data?.access_token);
        if (!response?.data.first_name) {
          set({ signInType: 'login' });
          status = SCREENS.USER_NAME;
        } else status = SCREENS.LOGIN_SUCCESS;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'loginError');
      }
    } catch (err) {
      set({ error: 'There was an error logging in' }, false, 'loginError');
      get().setLoading(false);
    }
    get().setLoading(false);
    return status;
  },

  /**
   * @param {object} props - The user registration props.
   * @param {string} props.method - The method used for tracking, in the form ELEMENT:ACTION ('SubmitButton:Click').
   * @param {object} props.registrationObj - The registration object containing user details.
   * @param {string} props.registrationObj.first_name - The user first name.
   * @param {string} props.registrationObj.last_name - The user last name.
   * @param {string} props.registrationObj.email - The user email.
   * @param {string} props.registrationObj.password - The user password.
   * @param {string} props.registrationObj.birth_year - The user birth year.
   */
  userRegistration: async ({ method, registrationObj = {} }) => {
    let status;
    get().setLoading(true);

    const body = Object.fromEntries(
      ALLOWED_USER_REGISTRATION_FIELDS
        .map((field) => [field, registrationObj[field]])
        .filter(([, value]) => value !== undefined),
    );

    try {
      const response = await api('/users/register', {
        body,
      });

      // Successful Registration
      if (response?.success) {
        get().track({
          action: 'SignUp:Success',
          method,
          authState: 'authenticated',
          userId: response?.data?.user_id,
        });

        get().setLoading(false);
        set({
          signInType: 'registration',
        }, false, 'registrationSuccess');

        status = SCREENS.VERIFY_OTC;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'registrationError');
      }
      // Api error
    } catch (err) {
      set({ error: 'There was an error registering' }, false, 'registrationError');
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Handles user registration with social login
   * @param {object} props - The user registration props.
   * @param {object} props.trackingPayload - The tracking payload object.
   */
  userSocialRegistration: async ({ trackingPayload }) => {
    let status;

    try {
      // track the start of the social registration process
      get().track(trackingPayload);
      const response = await api('/users/social/signin', {
        body: {
          id_token: get().social.idToken,
          brand: get().brand,
          platform: get().social.platform,
          first_name: get().social.firstName,
        },
      });

      if (response?.success) {
        // track successful registraion
        const successPayload = {
          action: 'SignIn:Success',
          // same method as the initial tracking payload
          method: `${trackingPayload.method}`,
          userId: response?.data?.user_id,
        };
        get().track(successPayload);

        set({ user: response?.data?.access_token }, false, 'userSocialRegistration');
        get().restoreSession(response?.data?.access_token);
        if (!response?.data?.first_name) {
          set({ signInType: 'registration' });
          status = SCREENS.USER_NAME;
        } else status = SCREENS.REGISTRATION_SUCCESS;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSocialRegistration');
      }
    } catch (err) {
      set({ error: 'There was an error registering' }, false, 'userSocialRegistration');
      return status;
    }
    return status;
  },

  /**
   * Updates the user profile
   * @param {object} props - The user profile update props.
   * @param {object} props.trackingPayload - The tracking payload object.
   * @param {object} props.profileUpdateObj - The profile update object containing user details.
   * @param {string} props.profileUpdateObj.first_name - The user first name.
   * @param {string} props.profileUpdateObj.last_name - The user last name.
   * @param {string} props.profileUpdateObj.birth_year - The user birth year.
   */
  userProfileUpdate: async ({ profileUpdateObj = {}, trackingPayload }) => {
    let status;
    get().setLoading(true);
    // track the start of the profile update process
    get().track(trackingPayload);
    try {
      await get().getUserToken(get().user.user_id);
    } catch (err) {
      set({ error: err.message }, false, 'getUserTokenError');
      get().setLoading(false);
      return err.message;
    }

    const body = Object.fromEntries(
      Object.entries(profileUpdateObj).filter(
        ([key, value]) => ALLOWED_PROFILE_FIELDS.includes(key) && value !== undefined,
      ),
    );

    try {
      const response = await api(`/users/${get().user.user_id}/profile`, {
        method: 'PATCH',
        headers: {
          'x-auth-token': get().access_token,
        },
        body: {
          ...body,
          access_token: get().access_token,
          bedrock_session: getCookie('bedrock_session'),
        },
      });

      if (response?.success) {
        const successPayload = {
          action: 'SignUp:Success',
          method: `${trackingPayload.method}`,
        };
        get().track(successPayload);
        get().restoreSession(get().access_token);
        status = get().signInType === 'registration'
          ? SCREENS.REGISTRATION_SUCCESS
          : SCREENS.LOGIN_SUCCESS;
      } else {
        // Set error message from API response if update fails.
        set({ error: response?.error?.user_message }, false, 'userProfileUpdateError');
      }
    } catch (err) {
      // Handle network or unexpected errors.
      set({ error: err.message }, false, 'userProfileUpdateError');
    } finally {
      get().setLoading(false);
    }
    return status;
  },

  /**
   * Sets the AuthenticationModal state in the store.
   * @param {boolean} showAuthenticationModal defaults to showing the modal
   */
  setAuthenticationModal: (showAuthenticationModal = true) => set({ showAuthenticationModal }, false, 'setAuthenticationModal'),

  /**
   * Sets the screen
   * @param {string} screen screen
   * @returns {void}
   */
  setScreen: (screen) => {
    set({ screen, error: '' }, false, 'setScreen');
    if (screen === SCREENS.LOGIN_SUCCESS) {
      set({ showGate: false }, false, 'setScreen');
    }
  },

  /**
   * Sets the pageView
   * @param {string} pageView - The page view.
   * @returns {void}
   */
  setPageView: (pageView) => {
    set({ pageView }, false, 'setPageView');
  },

  /**
   * Sets the overlay
   * @param {boolean} isOverlay - The overlay state.
   * @returns {void}
   */
  setIsOverlay: (isOverlay) => {
    set({ isOverlay }, false, 'setIsOverlay');
  },
  /**
   * Sets the error
   * @param {string} error error message
   * @returns {void}
   */
  setError: (error) => set({ error }, false, 'setError'),

});

/**
 * Enable the devtools in development mode only
 */
const isDevelop = process.env.NODE_ENV === 'development';

const devToolsProps = {
  name: 'useBedrockRegistration',
  anonymousActionType: 'action',
  serialize: true,
  // eslint-disable-next-line jsdoc/require-jsdoc
  actionSanitizer: (action) => ({
    ...action,
    type: `BedrockRegistration/${action.type}`,
  }),
};

// eslint-disable-next-line jsdoc/require-jsdoc
const middlewares = (f) => (isDevelop ? devtools(f, devToolsProps) : f);

/* Create Store */
export const useBedrockRegistration = create(middlewares(bedrockRegistrationStore));
