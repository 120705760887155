import React, { useState } from 'react';
import { useBedrockRegistration } from 'store';
import { Button } from 'components/Button';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import Link from 'components/Link';
import { SCREENS } from 'lib/loginFormConstants';
import cx from 'classnames';
import { passwordLoginSchema } from '../validationSchema';
import { HideEye } from '../Icons/HideEye/index';
import { ShowEye } from '../Icons/ShowEye/index';

import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';

/**
 * LogInScreen component for handling log in with a password.
 * @returns {React.ReactElement} The rendered component.
 */
const LogInScreen = () => {
  const user = useBedrockRegistration((state) => state.user);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const userSignin = useBedrockRegistration((state) => state.userSignin);
  const requestOneTimeCode = useBedrockRegistration((state) => state.requestOneTimeCode);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Handles one time code click
   * passes payload to requestOneTimeCode for tracking
   * sets screen on succewss
   */
  const handleOTCClick = () => {
    const trackingPayload = {
      action: 'SignIn:Click',
      method: 'OneTimeCodeButton:Click',
    };
    requestOneTimeCode({ trackingPayload }).then(() => {
      // update the screen value based on the response
      setScreen(SCREENS.LOGIN_OTC);
    });
  };

  return (
    <>
      <h2
        className={styles.heading}
        data-testid="account-login"
      >
        Log in to your Account
      </h2>
      <p className={globalStyles.userEmail}>
        {user.email || 'example@example.com'}
      </p>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={passwordLoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          const trackingPayload = {
            action: 'SignIn:Submit',
            method: 'SubmitButton:Click',
          };
          userSignin({
            password: values.password,
            trackingPayload,
          })
            .then((result) => {
              setSubmitting(false);
              // update the screen value based on the response
              if (result) {
                setScreen(result);
              }
            });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          errors,
        }) => (
          <Form onSubmit={handleSubmit} data-testid="account-password" className={globalStyles.form}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="password" className={globalStyles.label}>Please enter your password.</label>
            <div className={styles.field}>
              <Field
                name="password"
                type={showPassword ? 'text' : 'password'}
                aria-label="password"
                onChange={handleChange}
                className={errors.password ? globalStyles.errorInput : ''}
              />
              <button
                type="button"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                className={globalStyles.eye}
              >
                {showPassword ? <HideEye /> : <ShowEye />}
              </button>
            </div>

            <ErrorMessage name="password" component="div" className={cx(styles.error, globalStyles.errorMessage)} />
            <Button
              tiltClasses={globalStyles.button}
              displayType="submit"
              title="Log In"
              disabled={isSubmitting}
            />
            <div className={styles.links}>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    handleOTCClick();
                  }}
                  className={globalStyles.buttonLink}
                >
                  Log in with one time code
                </button>
              </div>
              <div><Link to="https://profile.id.nbcuni.com/forgot-password" target="_blank" className={globalStyles.buttonLink}>Forgot Password</Link></div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { LogInScreen };
