const akaasKeys = [
  'akaas_NBCNews',
  'akaas_MSNBC',
  'akaas_TODAY',
  'akaas_TELEMUNDO',
];

/**
 * Retrieves the value of a specified cookie.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie, or null if not found.
 */
export function getCookie(name) {
  try {
    const cookies = document?.cookie?.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`) ?? null;
    return cookies ? cookies.pop() : null;
  } catch (e) {
    return null;
  }
}

/**
 * Sets a cookie value
 *
 * @param {string} name the cookie name
 * @param {string} value the cookie value
 * @param {string} [expiration] the expiration length in days; if not provided, defaults to null (session cookie)
 */
export const setCookie = (name, value, expiration = null) => {
  if (document) {
    let expires = '';
    if (expiration) {
      /**
       * expiration should be in days, convert to milliseconds for Date
       * e.g. 1 day = 24 * 60 * 60 * 1000
       */
      const d = new Date();
      d.setTime(d.getTime() + expiration * 24 * 60 * 60 * 1000);
      expires = `expires=${d.toUTCString()};`;
    }

    document.cookie = `${name}=${value};path=/;${expires}`;
  }
};

/**
 * Deletes a cookie by setting its expiration date to the past.
 *
 * @param {string} name - The name of the cookie to delete.
 */
export function deleteCookie(name) {
  if (document) {
    document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  }
}

/**
 * Extracts the value of the "rv" key from a cookie string.
 *
 * @param {string} cookie - The cookie string to parse.
 * @returns {string|undefined} The value of the "rv" key, or undefined if not found.
 */
const getRvVal = (cookie) => {
  const rvMatch = cookie && cookie.match('(^|~)\\s*rv\\s*=\\s*([^~]+)');
  return rvMatch?.[2];
};

/**
 * Checks if a value is within a specified range.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {number} val - The value to check.
 * @returns {boolean} True if the value is within the range, otherwise false.
 */
const isWithinRange = (min, max, val) => min <= val && max >= val;

// takes an array of tuples of options to percentages: [['a', 2], ['b', 2], ['c', 96]]
// returns 'a' with probability of 2%, 'b' with probability of 2%, c with probablity of 96%
// we use an array rather than an object because it's ordered
// Option values do not need to add up to 100. Function will return null if a user has a
// rv cookie outside of the given ranges.
/**
 * Determines a cookie option based on the provided options and the user's rv cookie value.
 *
 * @param {Array<[string, number]>} options - An array of tuples where each tuple contains an option and its percentage probability.
 * @returns {string|null} The selected option based on the rv cookie value, or null if no valid option is found.
 */
export const cookieOptionDecider = (options) => {
  let cookieName;

  for (let i = 0; i < akaasKeys.length; i += 1) {
    const akaasKey = akaasKeys[i];
    cookieName = getCookie(akaasKey);

    if (cookieName) break;
  }

  const cookieVal = parseInt(getRvVal(cookieName), 10);
  if (!cookieVal) return null;

  let curr = 0;

  const [testingOption] = options.find(([, percent]) => {
    const inRange = isWithinRange(curr, curr + percent, cookieVal);
    curr += percent;

    return inRange;
  }) || [];

  return testingOption ?? null;
};

// Helpers for BENTO-16393 to format the value of the featureFlag cookie.
/**
 * Splits a feature flag value string into an object with key-value pairs.
 *
 * @param {string} val - The feature flag value string to split.
 * @returns {object} An object containing key-value pairs extracted from the input string.
 */
export const explodeFeatureFlagValue = (val) => {
  const ret = {};
  val.split('~').forEach((v) => {
    const [key, value] = v.split('=');
    ret[key] = value;
  });
  return ret;
};

/**
 * Combines an object of key-value pairs into a feature flag value string.
 *
 * @param {object} val - An object containing key-value pairs to combine.
 * @returns {string} A string representation of the feature flag values.
 */
export const implodeFeatureFlagValue = (val) => {
  const ret = [];
  Object.keys(val).forEach((v) => {
    ret.push(`${v}=${val[v]}`);
  });
  return ret.join('~');
};
