/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Reaptcha from 'reaptcha';

import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';
import { Formik, Field, ErrorMessage } from 'formik';
import { SocialLogin } from 'components/AccountLoginRegistration/SocialLogin';
import { Circles } from 'components/Gate/Circles';
import { SCREENS } from 'lib/loginFormConstants';
import { useVertical } from 'lib/Hooks';
import { pickSiteKey } from './verticalSitekeys';
import globalStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { emailSchema } from '../validationSchema';

/**
 * EmailScreen component for handling email input and submission.
 * @param {object} props - The component props.
 * @param {string} props.entryTitle - The title of the entry screen.
 * @param {boolean} props.isOverlay - To check whether the layout is Overlay.
 * @param {string} props.pageView - The page type.
 * @param {string} props.currentScreen - current screen.
 * @param {Function} props.track - tracking function, takes a payload of action and method
 * @returns {React.ReactElement} The rendered component.
 */
const EmailScreen = ({
  entryTitle, isOverlay, pageView, currentScreen,
}) => {
  const showAuthenticationModal = useBedrockRegistration((state) => state.showAuthenticationModal);
  const setEmail = useBedrockRegistration((state) => state.setEmail);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const { email } = useBedrockRegistration((state) => state.user);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);
  const setError = useBedrockRegistration((state) => state.setError);
  const track = useBedrockRegistration((state) => state.track);
  const [captcha, setCaptcha] = useState(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const { vertical } = useVertical();
  const siteKey = pickSiteKey(vertical);
  const inputRef = useRef();
  const emailRef = useRef('');

  useEffect(() => {
    if (showAuthenticationModal && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  /**
   * Captcha verification callback.
   * Updates the global email state using the latest email from emailRef,
   * then performs the email lookup.
   */
  const captchaVerify = async () => {
    setEmail(emailRef.current);
    const response = await searchUserByEmail(emailRef.current, 'today');
    if (response.success) {
      setScreen(SCREENS.LOGIN_PASSWORD);
    } else {
      setScreen(SCREENS.CREATE_ACCOUNT);
    }
  };

  /**
   * Handles loading of the Reaptcha component.
   */
  const onLoad = () => {
    setCaptchaLoaded(true);
  };

  return (
    <>
      <div className={styles.content}>
        <h2 className={styles.title}>{entryTitle}</h2>
        <Formik
          initialValues={{ email }}
          validationSchema={emailSchema}
          onSubmit={async (values, { setSubmitting }) => {
            if (captcha && captchaLoaded) {
              setEmail(values.email);
              captcha.execute();
              track({
                action: 'SignUp:Submit',
                method: 'SubmitButton:Click',
              });
            } else {
              setError('There was an error submitting your email. Please try again.');
            }
            setSubmitting(false);
          }}
        >
          {({ errors, handleChange, handleBlur }) => (
            <form data-testid="account-email" className={globalStyles.form}>
              <label htmlFor="email" className={globalStyles.label}>
                Please enter your email.
              </label>
              <Reaptcha
                ref={(instance) => setCaptcha(instance)}
                sitekey={siteKey}
                onVerify={captchaVerify}
                onLoad={onLoad}
                size="invisible"
              />
              <Field
                id="email"
                type="email"
                name="email"
                aria-label="email"
                className={errors.email ? globalStyles.errorInput : ''}
                innerRef={inputRef}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage name="email">
                {(msg) => (
                  // eslint-disable-next-line max-len
                  <div className={cx(styles.error, globalStyles.errorMessage, globalStyles.errorMessageEmail)}>
                    {msg}
                  </div>
                )}
              </ErrorMessage>
              <Button
                tiltClasses={globalStyles.button}
                displayType="submit"
                title="Continue"
                onClick={() => {
                  if (captcha && captchaLoaded) {
                    emailRef.current = inputRef.current.value || '';
                    if (!emailSchema.isValidSync({ email: emailRef.current })) {
                      setError('Please enter a valid email');
                      return;
                    }
                    captcha.execute();
                    track({
                      action: 'SignUp:Start',
                      method: 'EmailScreen:SubmitButton:Click',
                    });
                  } else {
                    setError('There was an error submitting your email. Please try again.');
                  }
                }}
              />
              <div className={styles.separator}>
                <span>or</span>
              </div>
            </form>
          )}
        </Formik>
        <div className={styles.bottom}>
          {isOverlay || !showAuthenticationModal
            ? (
              <SocialLogin
                track={track}
                pageView={pageView}
                currentScreen={currentScreen}
              />
            )
            : null}
          <button
            type="button"
            className={globalStyles.buttonLink}
            onClick={() => {
              track({
                action: 'SignUp:Submit',
                method: 'PoweredByNBCUButton:Click',
              });
              setScreen(SCREENS.NBCU_PROFILE);
            }}
            data-testid="nbcu-profile-button"
          >
            Powered by NBCUniversal Profile
          </button>
        </div>
      </div>
      <div className={cx(styles.circleWrapper, styles.circleLeft)}>
        <Circles />
      </div>
      <div className={cx(styles.circleWrapper, styles.circleRight)}>
        <Circles />
      </div>
    </>
  );
};

EmailScreen.propTypes = {
  entryTitle: PropTypes.string.isRequired,
};

export { EmailScreen };
