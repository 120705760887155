import React, { useEffect, useState } from 'react';
import { stub as $t } from '@nbcnews/analytics-framework';

import { AccountLoginRegistration, AccountLoginRegistrationCore } from 'components/AccountLoginRegistration';
import { SCREENS } from 'lib/loginFormConstants';
import { useBedrockRegistration } from 'store';
import CloseIcon from './close.svg';
import styles from './styles.module.scss';

/**
 * Overlay component for displaying the authentication modal.
 * @param {object} props - The props object.
 * @param {string} props.pageView - The page view identifier.
 * @param {boolean} props.useUnwrappedAuth - Flag to determine which authentication component to use.
 * @returns {React.ReactElement} The rendered component.
 */
const Overlay = ({ pageView, useUnwrappedAuth }) => {
  const showAuthenticationModal = useBedrockRegistration((state) => state.showAuthenticationModal);
  const setAuthenticationModal = useBedrockRegistration((state) => state.setAuthenticationModal);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const currentScreen = useBedrockRegistration((state) => state.screen);
  const [lastFocus, setLastFocus] = useState({});
  const AuthComp = useUnwrappedAuth ? AccountLoginRegistrationCore : AccountLoginRegistration;
  /**
   * Closes the authentication modal and restores the previous state of the document.
   *
   * This function performs the following actions:
   * - Sets the authentication modal state to false.
   * - Removes the 'inert' attribute from the content element to make it interactive again.
   * - Restores the body's overflow style to 'auto' to allow scrolling.
   * - Returns focus to the last focused element before the modal was opened.
   * - Sets the screen to the start screen
   */
  const handleCloseModal = () => {
    setAuthenticationModal(false);
    document.getElementById('content').inert = false;
    document.body.style.overflow = 'auto';
    lastFocus.focus();

    $t('track', 'ramen_authflow', {
      action: 'Button:Click',
      value: 'CloseButton',
      screen: currentScreen,
    });

    setScreen(SCREENS.START);
  };

  useEffect(() => {
    if (showAuthenticationModal) {
      setLastFocus(document.activeElement);
      const contentEl = document.getElementById('content');
      if (contentEl) contentEl.inert = true;
      document.body.style.overflow = 'hidden';
    }
  }, [showAuthenticationModal]);
  return (
    showAuthenticationModal ? (
      <dialog
        open={showAuthenticationModal}
        className={styles.dialog}
        data-testid="account-overlay"
      >
        <div className={styles.content}>
          <button
            className={styles.close}
            type="button"
            onClick={handleCloseModal}
            aria-label="Close"
          >
            <img alt="close" src={CloseIcon} />
          </button>
          <AuthComp
            pageView={pageView}
            isOverlay
            callback={handleCloseModal}
          />
        </div>
      </dialog>
    ) : null
  );
};

export { Overlay };
