import React from 'react';
import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store/bedrockRegistration';

import styles from './styles.module.scss';


/**
 * TodaySVG is a React functional component that renders an SVG graphic.
 * The SVG graphic represents the TODAY logo in a positive full-color format.
 * @returns {JSX.Element} The rendered SVG component.
 */
const TodaySVG = () => (
  <svg width="201" height="42" viewBox="0 0 201 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="*TODAY* / Stacked / Positive Full Color">
      <g id="Group 5">
        <path id="TODAY" fillRule="evenodd" clipRule="evenodd" d="M25.0849 38.9706V10.0709H38.4852V1.81383H1.52734V10.0709H14.744V38.9706H25.0849ZM80.8597 20.3899C80.8597 31.8319 72.0486 39.6761 60.8511 39.6761C49.6536 39.6761 40.8425 31.8319 40.8425 20.3899C40.8425 8.94798 49.6536 1.10376 60.8511 1.10376C72.0486 1.10376 80.8597 8.94798 80.8597 20.3899ZM70.1519 20.3922C70.1519 14.0815 66.5418 9.95294 60.8513 9.95294C55.1608 9.95294 51.5507 14.0815 51.5507 20.3922C51.5507 26.703 55.1608 30.8315 60.8513 30.8315C66.5418 30.8315 70.1519 26.703 70.1519 20.3922ZM96.8623 31.0633H100.962C107.693 31.0633 111.67 27.1707 111.67 20.3291C111.67 13.6055 107.693 9.7129 100.962 9.7129H96.8623V31.0633ZM86.4608 38.9706V1.81378H101.697C114.485 1.81378 122.378 8.77331 122.378 20.3922C122.378 32.0111 114.485 38.9706 101.697 38.9706H86.4608ZM138.563 1.81378H150.189L165.547 38.9706H154.472L151.596 31.7751H136.544L133.668 38.9706H123.205L138.563 1.81378ZM143.949 13.3143L139.544 24.2844H148.6L144.194 13.3143H143.949ZM180.306 15.202L188.566 1.81378H199.764L184.956 24.8746V38.9706H174.86V24.8746L160.053 1.81378H171.617L180.306 15.202Z" fill="#FF503C" />
      </g>
    </g>
  </svg>
);
/**
 * SunsetSVG is a functional React component that renders an SVG illustration of a sunset.
 * The SVG consists of three layered paths with different fill colors to create a sunset effect.
 * @returns {JSX.Element} An SVG element representing a sunset.
 */
const SunsetSVG = () => (
  <svg width="200" height="97" viewBox="0 0 200 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M199.914 92.5635C197.772 41.0931 153.861 0 100.004 0C46.5198 0 2.83158 40.5382 0.136548 91.5161C0.0431205 93.1809 0 94.8457 0 96.5175H25.9298C25.9298 94.9012 25.9873 93.278 26.1023 91.6826C28.6895 54.5018 60.843 25.0277 100.004 25.0277C139.308 25.0277 171.562 54.7307 173.934 92.1057C174.027 93.5694 174.077 95.033 174.077 96.5175H200C200 95.1995 199.978 93.8676 199.914 92.5635" fill="#FFD2C8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M100.005 32.1736C64.6607 32.1736 35.7484 58.7204 33.4846 92.2802C33.3768 93.6745 33.3408 95.0896 33.3408 96.5185H59.2634C59.2634 94.9855 59.3568 93.4595 59.5365 91.9681C61.8794 72.4343 79.142 57.1943 100.005 57.1943C120.983 57.1943 138.31 72.5799 140.51 92.2663C140.668 93.6676 140.747 95.0757 140.747 96.5185H166.669C166.669 95.1381 166.626 93.7924 166.54 92.4328C164.355 58.7967 135.4 32.1736 100.005 32.1736" fill="#FFA193" />
    <path fillRule="evenodd" clipRule="evenodd" d="M100.004 64.3484C83.7476 64.3484 70.2078 75.572 67.2684 90.4304C66.8803 92.4005 66.6719 94.4399 66.6719 96.5278H133.336C133.336 94.9601 133.221 93.434 132.998 91.9496C130.699 76.342 116.807 64.3484 100.004 64.3484" fill="#FF503C" />
  </svg>

);
/**
 * SuccessScreen is a functional React component that renders a success screen
 * for account login or registration. It includes a welcome message, an SVG graphic,
 * and a button to navigate to the account page.
 * @returns {JSX.Element} The rendered success screen component.
 */
const SuccessScreen = () => {
  const track = useBedrockRegistration((state) => state.track);

  return (
    <>
      <SunsetSVG />
      <h2
        className={styles.heading}
        data-testid="account-login-success"
      >
        Welcome to
      </h2>
      <TodaySVG />

      <p className={styles.description}>
        Thank you for joining us,
        <br />
        we&apos;re so happy you&apos;re here!
      </p>

      <Button
        tiltClasses={styles.submitButton}
        displayType="no-arrow"
        title="Get Started"
        onClick={
          () => {
            track({
              action: 'SignIn:Click',
              value: 'GetStartedButton',
            });
            window.location.href = '/account';
          }
        }
      />
    </>
  );
};

export { SuccessScreen };
