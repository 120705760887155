import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { useVertical } from 'lib/Hooks';
import getConfig from 'next/config';
import { decodeJWT } from 'store/utils';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_GOOGLE_CLIENT_ID,
  },
} = getConfig();

/**
 * Renders a Login with Google Button
 */
const GoogleLoginButton = () => {
  const { vertical } = useVertical();
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);

  /**
   * Handles click on Login with Google Button
   * @param {object} response
   */
  const handleCredentialResponse = async (response) => {
    const idToken = response.credential;
    // get user information from token response
    // eslint-disable-next-line camelcase
    const { email, given_name } = decodeJWT(idToken);
    // check if user has an account
    const res = await searchUserByEmail(email, 'today');
    const isNewUser = !res?.success;
    const socialLoginObj = {
      socialUser: {
        idToken,
        firstName: given_name,
        email,
        isNewUser,
      },
      vertical,
      platform: 'google',
      trackingPayload: {
        action: 'SignIn:Submit',
        method: 'SocialLogin:Google:Click',
      },
    };
    const status = await socialLogin(socialLoginObj);
    if (status) {
      setScreen(status);
    }
  };

  useEffect(() => {
    // Load Google Identity Services SDK script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;

    /**
     * Handles Google SDK script load
     */
    script.onload = () => {
      // Initialize the Google Sign-In button
      window.google.accounts.id.initialize({
        client_id: TODAY_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // Use GSI javascript api to render the button inside our wrapper
      window.google.accounts.id.renderButton(document.getElementById('google-login-button'), {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        shape: 'rectangular',
        logo_alignment: 'center',
        width: '280',

      });
    };

    document.body.appendChild(script);
  }, []);

  return (
    <div
      role="toolbar"
      id="google-login-button"
      className={styles.googleLogin}
      data-testid="google-login-btn"
    />
  );
};

export { GoogleLoginButton };
