import React from 'react';
import { AppleLoginButton } from './AppleLogin/index';
import { GoogleLoginButton } from './GoogleLogin/index';

import styles from './styles.module.scss';

/**
 * Displays Apple and Google login buttons
 * @param {object} props - The component props.
 *  @param {Function} props.track - The tracking function.
 * @param {string} props.pageView - The page type.
 * @param {string} props.currentScreen - current screen.
 * @returns {React.ReactElement} The rendered component.
 */
const SocialLogin = ({
  track, pageView, currentScreen,
}) => (
  <div data-testid="social-login" className={styles.socialLogin}>
    <GoogleLoginButton
      track={track}
      pageView={pageView}
      currentScreen={currentScreen}
    />
    <AppleLoginButton
      track={track}
      pageView={pageView}
      currentScreen={currentScreen}
    />
  </div>
);

export { SocialLogin };
